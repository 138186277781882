import React from 'react'
import { Avatar } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  avatar: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
  },
  shadow: {
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2)',
  },
  disableClick: {
    cursor: 'default',
  },
}))

const getSizeOptionBySize = (size) => {
  if (!size) return 'xl'
  if (size <= 16) return 'xs'
  if (size <= 32) return 'sm'
  if (size <= 64) return 'lg'
  if (size <= 128) return 'xl'
  return 'xxl'
}

const EventAvatar = ({
  event,
  fallback,
  size,
  className,
  style,
  shadow,
  avatarClass,
  disableClick,
  onClick,
}) => {
  const { classes, cx } = useStyles()

  if (!event) return null

  const sizeOption = getSizeOptionBySize(size)
  const imageUrls = event.imageUrls || {}
  const fallbackUrls = fallback?.avatarUrls ||
    fallback?.avatar?.urls || { xl: fallback?.avatarUrl }
  const thumbnailUrl = event.media?.[0]?.content?.thumbnail?.url

  const handleClick = (e) => {
    if (disableClick) return
    if (onClick) {
      e.preventDefault()
      e.stopPropagation()
      onClick(e)
    }
  }

  return (
    <Avatar
      onClick={handleClick}
      alt={`${event.name} image`}
      src={
        sizeOption in imageUrls
          ? imageUrls[sizeOption]
          : Object.values(imageUrls)[0] ||
            thumbnailUrl ||
            (sizeOption in fallbackUrls
              ? fallbackUrls[sizeOption]
              : Object.values(fallbackUrls)[0])
      }
      style={{ width: size, height: size, ...style }}
      className={cx(className, avatarClass, classes.avatar, {
        [classes.shadow]: shadow,
        [classes.disableClick]: disableClick,
      })}
    />
  )
}

export default EventAvatar
